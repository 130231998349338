import React, { useState } from 'react';
import {auth} from '../../Firebase/firebase-config'
import firebase from 'firebase/compat/app'
import {navigate} from "gatsby"





const FormLogin = () => {
    //#region SECTION GLOBALS VARIABLES WHIT'S SET
    const [email,setEmail] = useState('');
    const [pass,setPass] = useState('');
    const [emailLogin, setEmailLogin] = useState('');
    const [passLogin, setPassLogin] = useState('');
    const [status, setStatus] = useState(0);//0 = register 1=login
    const [MsgError,setMsgError] = useState(null)
    //#endregion

    function View(){
        if(status === 0){
            return (
                <>
                    <form className="row" onSubmit={UserRegister} id="frmRegister">
                        <div className="form-group  p-2">
                            <label htmlFor="txtEmail">Correo</label>
                            <input
                                type="email" 
                                className="form-control" 
                                id="txtEmail" 
                                aria-describedby="emailHelp" 
                                placeholder="Enter email" 
                                onChange={(e)=>{setEmail(e.target.value)}}
                            />
                            <small 
                                id="emailHelp" 
                                className="form-text text-muted">
                                Con el correo podra ingresar, se enviara un correo de confirmación. 
                            </small>
                        </div>
                        <div className="form-group p-2"> 
                            <label htmlFor="txtPassword">Contraseña</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                id="txtPassword"
                                placeholder="Password"
                                onChange={(e)=>{setPass(e.target.value)}} 
                            />
                            <small id="passwordHelp" className="form-text text-muted">Debe ser mayor de 6 digitos contener mayusculas y minusculas.</small>
                        </div>
                        <button type="submit" className="btn btn-success btn-block p-2">Registrarse</button>
                    </form>
                </>
            );
        }else{
            return(
                <>
                    <form className="row"  id="frmLogin">
                        <div className="form-group  p-2">
                            <label htmlFor="txtEmail">Correo</label>
                            <input
                                type="email" 
                                className="form-control" 
                                id="emailLogin" 
                                aria-describedby="emailHelp" 
                                placeholder="Ingresa tu correo" 
                                onChange={(e)=>{setEmailLogin(e.target.value)}}
                            />
                            <small 
                                id="emailHelp" 
                                className="form-text text-muted">
                                Ingresa el correo con el que te registrarte.
                            </small>
                        </div>
                        <div className="form-group p-2"> 
                            <label htmlFor="txtPassword">Contraseña</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                id="passwordLogin"
                                placeholder="Contraseña"
                                onChange={(e)=>{setPassLogin(e.target.value)}} 
                            />
                            <small id="passwordHelp" className="form-text text-muted">Ingresa con la contraseña que te registraste.</small>
                        </div>
                    </form>
                    <div className="row">
                    <button type="submit" className="btn btn-success btn-block p-2 m-1">Ingresar con correo</button>
                    <button className="btn btn-primary btn-block p-2 m-1 text-white">Ingresar con facebook</button>
                    <button 
                        className="btn btn-dark btn-block p-2 m-1 text-white"
                        onClick={()=>{LoginWithGoogle()}}
                    >Ingresar con Google</button>
                </div>
                </>
            );
        }
    }

    //#region USER REGISTER
    const UserRegister = (e)=>{
        e.preventDefault()
        auth.createUserWithEmailAndPassword(email,pass)
        .then(()=> {
            alert('Registrado correctamente');
            navigate('../en-proceso');
        })
        .catch((e)=>{
                /* ALT+SHIF+A or CTRL+/ */
            if(e.code === 'auth/invalid-email'){
                setMsgError('Formato Email incorrecto');
                alert(MsgError);
            }
            if(e.code === 'auth/weak-password'){
                setMsgError('La password tiene que tener 6 caracteres o mas');
                alert(MsgError);

            }
        })
    }
    //#endregion

    //#region USER LOGIN
    const UserLogin = ()=>{
        auth.signInWithEmailAndPassword(email,pass)
        .then((r)=> {
            navigate('/prueba-firestore');
        })
        .catch((e)=>{
            if(e.code === 'auth/user-not-found'){
                setMsgError('autenticacion fallida')
            }
            if(e.code === 'auth/wrong-password'){
                setMsgError('Password incorrecta')
            }
        })
    }
    //#endregion
    const LoginWithGoogle = ()=>{
        //preper login google
        const providerGoogle =  new firebase.auth.GoogleAuthProvider();
        const authWithGoogle = auth.signInWithPopup(providerGoogle);
        authWithGoogle
        .then(result =>{
            console.log('Google Sign In')
        })
        .catch(err =>{
            console.log(err);
        })
    };

    const LoginWhitFacebook = ()=>{
        const provider = new firebase.auth.FacebookAuthProvider();
        auth.signInWithPopup(provider)
        .then(result =>{
            console.log(result);
            console.log('facebook sign in');
        })
        .catch(err =>{
            console.log(err);
        })
    };
    
    return (
        <>
            
           <div className="container p-4">
                <nav className="nav nav-pills nav-justified">
                    <button 
                        className="nav-item nav-link active"
                        onClick={()=>{setStatus(0)}}
                    >Registrarse</button>
                    <button 
                        className="nav-item nav-link"
                        onClick={()=>{setStatus(1)}}
                    >Ingresar</button>
                </nav>
                <br /><br />
                <View />    
           </div>
        </>
    )
}

export default FormLogin

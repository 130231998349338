import React, { useState } from 'react';
import { Button, Modal} from 'react-bootstrap';
import FormLogin from "../../Forms/FormLogin";

const ModalRegistro = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    return (
        <>
            <Button variant="success" size="lg" onClick={handleShow}>Registrarse</Button>{' '}
            <Modal show={show} onHide={handleClose}>
            <Modal.Header >
                <Modal.Title>Bienvenido a la familia Argos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormLogin />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Close
                </Button>
            </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalRegistro

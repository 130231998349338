import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap';
import ModalRegistro from "../components/Modals/ModalRegistro"

const enProceso = () => (
  <Layout>
    <Seo title="Página en proceso" />
    <Container fluid className="img-theme">
      <Row className="justify-content-md-center">
        <Col xs="12" md="12" lg="12">
          <p className="text-center">
            <img className="logo-tools" src="https://i.postimg.cc/nVDwhX1K/logotipo-Mesa-de-trabajo-1.png" alt="Logo "></img>
          </p>
          <h2 className="text-center text-white">Nuestra pagina esta en proceso esperamos pronto poder atenderte</h2>
          <p className="text-center text-white">Registrate ahora nuestros primeros 50 usuarios tendran acceso gratis a nuestra plataforma.</p>
          <p className="text-center text-white">
            <ModalRegistro />
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)
export default enProceso
